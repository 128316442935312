<template>
  <div class="flex items-center data-list-btn-container">
    <PopupLanguage
      v-model="isPopupActive"
      :on-click-btn="onClickBtn"
      name="property-asset-project-create"
    />
    <vs-button
      v-if="canAdd"
      color="primary" type="border"
      class="p-3 mb-4 mr-4 rounded-lg flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
      @click="addNewContent"
    >
      <feather-icon 
        icon="PlusIcon" 
        svg-classes="h-4 w-4" />
      <span class="ml-2 text-base text-primary">
        {{ buttonLabel }}
      </span>
    </vs-button>
    <span class="mb-4 mr-4">Switch Language:</span>
    <vs-dropdown 
      vs-custom-content 
      vs-trigger-click 
      class="cursor-pointer mb-4">
      <span class="cursor-pointer flex items-center i18n-locale">
        <img
          :src="require(`@/assets/images/flags/${$store.state.locale.currentLang}.png`)"
          :alt="$store.state.locale.currentLang"
          class="h-4 w-5"
        >
        <span class="hidden sm:block ml-2 uppercase">{{
          $store.state.locale.currentLang
        }}</span>
      </span>
      <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
        <vs-dropdown-item @click="updateLang('th')">
          <img 
            class="h-4 w-5 mr-1" 
            src="@/assets/images/flags/th.png" 
            alt="th" >
          &nbsp;Thai
        </vs-dropdown-item>
        <vs-dropdown-item @click="updateLang('en')">
          <img 
            class="h-4 w-5 mr-1" 
            src="@/assets/images/flags/en.png" 
            alt="en" >
          &nbsp;English
        </vs-dropdown-item>
        <vs-dropdown-item @click="updateLang('cn')">
          <img 
            class="h-4 w-5 mr-1" 
            src="@/assets/images/flags/cn.png" 
            alt="cn" >
          &nbsp;Chinese
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import PopupLanguage from '@/components/PopupLanguage'

export default {
  name: 'AddNewContent',
  components: { PopupLanguage },
  props: {
    name: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'Add New Content',
    },
    onClick: {
      type: Function,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showPopup: {
      type: Boolean,
      default: true
    },
    allowLangs: {
      type: Array,
      default: () => ['th', 'en', 'cn']
    }
  },
  setup(props, ctx) {
    const isPopupActive = ref(false)
    const updateLang = (value) => {
      ctx.emit('update-lang', value)

      ctx.root.$store.dispatch('locale/changeLang', value)
    }

    const onClickBtn = (value) => {
      if (value) ctx.root.$store.dispatch('locale/changeLang', value)

      if (typeof props.onClick !== 'undefined') {
        props.onClick(value)
      } else {
        ctx.root.$router.push({
          name: props.name,
        })
      }
    }

    const addNewContent = () => {
      if (props.showPopup) isPopupActive.value = true
      else onClickBtn()
    }

    const canAdd = computed(() => {
      const currentLang = ctx.root.$store.state.locale.currentLang
      return props.allowLangs.includes(currentLang) && !props.disabled
    })

    return {
      isPopupActive,
      addNewContent,
      onClickBtn,
      updateLang,
      canAdd
    }
  },
}
</script>

<style scoped></style>
